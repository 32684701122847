let ENV = 'DEV';

export const CONSTANTS = {
  API_BASE_URL:
 //   ENV === 'PROD' ? '' : 'http://127.0.0.1:8001',
  ENV === 'PROD' ? '' : 'https://fsmapp.socialdnalabs.com',
  API_MANDATE_PARAMS: '/api/method/sdl_user_management.sdk.api',
  API_FSM_MANDATE_PARAMS: '/api/method/sdl_fsm.sdk.api',
  API_MANDATE_FRAPPE_PARAMS: '/api/method',
  VERSION: 'v1',
  ENABLE_APPLY_COUPON_CODE: true,
  ENABLE_STORE_CREDIT: true,
  ENABLE_REDIRECT_FEATURE: true,
  ENABLE_PRODUCT_ENQUIRY_FEATURE: false,
  USE_SINGLE_ENQUIRY: false,
  USE_MULTI_ENQUIRY: false,
  DISPLAY_PRODUCT_PRICE_ON_PRODUCT_LISTING_CARD: true,
  DISPLAY_ADD_CART_BUTTON_ON_PRODUCT_LISTING_CARD: true,
  DISPLAY_ADD_CATALOG_BUTTON_ON_PRODUCT_LISTING_CARD: false,
  DISPLAY_WISHLIST_ICON_ON_PRODUCT_LISTING_CARD: true,
  DISPLAY_WISHLIST_ICON_ON_WEBNAVBAR: true,
  DISPLAY_CART_ON_WEBNAVBAR: true,
  DOES_PRODUCT_HAS_VARIANTS: false,
  MAX_CARDS_PER_ROW_LISTING_PAGE:2,
  DISPLAY_TAG_ON_PRODUCT_LISTING_CARD:true,
  DOMAIN_NAME: '',
  // "SHOW_MORE_ITEMS" IS A KEY WHOSE VALUE DECIDES WHETHER TO SHOW LOAD MORE BUTTON OR PAGINATION.
  // IT WILL CONTAIN TWO VALUES "paginate" or "load-more"
  ENABLE_MISSING_PARTS: true,
  ENABLE_PAGINATION: true,
  ENABLE_LOAD_MORE: false,
  SHOW_MORE_ITEMS: 'paginate',
  SHOW_FUTURE_STOCK_AVAILABILITY_TO_GUEST: true,
  ADD_TO_CART_FOR_GUEST: true,
  ALLOW_PAYMENT_GATEWAY: true,
  SHOW_TRANSPORTERS_LIST_TO_DEALER: true,
  ALLOW_REQUEST_QUOTATION: true,
  // "PRODUCT_LISTING_VIEW" IS A KEY WHOSE VALUE DECIDES WHETHER TO SHOW CARD VIEW OF LIST VIEW ON PRODUCT LISTING PAGE.
  // IT WILL CONTAIN TWO VALUES "list-view" or "grid-view"
  ENABLE_TOGGLE_PRODUCT_LISTING_VIEW: true,
  PRODUCT_LISTING_VIEW: 'grid-view',
  ENABLE_META_TAGS: true,
  ENABLE_SEARCH_TEXT: false,
  ENABLE_PAYMENT_INTEGRATION: false,
  DEFAULT_CURRENCY_VALUE: 'rupee',
  DEFAULT_LANGUAGE: 'en',
  ENABLE_SHOP_ON_AMAZON:false,
};
